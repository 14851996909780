import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import Unlock from "@/views/Unlock.vue";
import Home from "@/views/Home.vue";
import ActiviteDetail from "@/views/ActiviteDetail.vue";
import OpeVehicules from "@/views/OpeVehicules.vue";
import OpeActivites from "@/views/OpeActivites.vue";
import OpeActiviteCreation from "@/views/OpeActiviteCreation.vue";
import MonCalendrier from "@/views/MonCalendrier.vue";
import MonActivite from "@/views/MonActivite.vue";
import Recyclage from "@/views/Recyclage.vue";
import Benevoles from "@/views/Benevoles.vue";
import Benevole from "@/views/Benevole.vue";
import BenevoleCreate from "@/views/BenevoleCreate.vue";
import Declenchements from "@/views/Declenchements.vue";
import Parametrages from "@/views/Parametrages.vue";
import Profile from "@/views/Profile.vue";
import Onboarding from "@/views/Onboarding.vue";
import Tenues from "@/views/Tenues.vue";
import StatistiquesActivites from "@/views/Statistiques.vue";
import VerificationAssistee from "@/views/VerificationAssistee.vue";
import VerificationAssisteeLot from "@/views/VerificationAssisteeLot.vue";
import RestitutionFinanciere from "@/views/RestitutionFinanciere.vue";
import TableauDeBord from "@/views/TableauDeBord.vue";
import VerificationAssisteeMesVerifications from "@/views/VerificationAssisteeMesVerifications.vue";
import TechVerifications from "@/views/TechVerifications.vue";
import JeuxDeParis from "@/views/JeuxDeParis.vue";

// SCSS
import "../assets/scss/style.scss";
import { LocalStorageApp } from "@/utils/localstorage";
import { JwtManage } from "@/utils/jwt";
import { login } from "@/store";
import { Http } from "@/utils/http";
import ErrorPage from "@/views/404.vue";
import { bus } from "@/main";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "BASE_URL",
    meta: {
      title: "Timeline PCP8",
      auth: false,
    },
    beforeEnter(to, from, next) {
      next("/timeline");
    },
  },
  {
    path: "/tableau-de-bord",
    name: "Bord",
    component: TableauDeBord,
    meta: {
      title: "Tableau de bord - PCP8",
      auth: true,
      roles: "BOARD",
    },
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: Home,
    meta: {
      title: "Timeline - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: Onboarding,
    meta: {
      title: "Onboarding - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/mon-activite",
    name: "MonActivite",
    component: MonActivite,
    meta: {
      title: "Mon activité - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/mon-calendrier",
    name: "MonCalendrier",
    component: MonCalendrier,
    meta: {
      title: "Mon calendrier - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/mon-profil",
    name: "MonProfile",
    component: Profile,
    meta: {
      title: "Mon profil - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/recyclage",
    name: "Recyclage",
    component: Recyclage,
    meta: {
      title: "FC 2023 - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/jeux-de-paris-2024",
    name: "JeuxDeParis2024",
    component: JeuxDeParis,
    meta: {
      title: "Jeux de Paris 2024 - PCP8",
      auth: true,
      roles: "JOP2024",
    },
  },
  {
    path: "/declenchements/gestions",
    name: "DeclenchementGestions",
    component: Declenchements,
    meta: {
      title: "Déclenchements - PCP8",
      auth: true,
      roles: "OPE",
    },
  },
  {
    path: "/activites/gestions",
    name: "ActivitesGestions",
    component: OpeActivites,
    meta: {
      title: "Gestions des activités - PCP8",
      auth: true,
      roles: "OPE",
    },
  },
  {
    path: "/activite/:id",
    name: "ActivitesDetail",
    component: ActiviteDetail,
    meta: {
      title: "Activité - PCP8",
      auth: true,
      roles: "OPE",
    },
  },
  {
    path: "/tech/vehicules",
    name: "OpeVehicules",
    component: OpeVehicules,
    meta: {
      title: "Véhicules - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/tech/verifications",
    name: "TechVerifications",
    component: TechVerifications,
    meta: {
      title: "Vérifications - PCP8",
      auth: true,
      roles: "TECH",
    },
  },
  {
    path: "/verification-assistee",
    name: "VerificationAssistee",
    component: VerificationAssistee,
    meta: {
      title: "Vérification assistée - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/verification-assistee/mes-verifications",
    name: "VerificationAssisteeMesVerifications",
    component: VerificationAssisteeMesVerifications,
    meta: {
      title: "Mes Vérification assistée - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/verification-assistee/lot-:lot",
    name: "VerificationAssisteeLot",
    component: VerificationAssisteeLot,
    meta: {
      title: "Vérification assistée - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/verification-assistee/activite-:activite",
    name: "VerificationAssisteeWithActivite",
    component: VerificationAssistee,
    meta: {
      title: "Vérification assistée - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/verification-assistee/activite-:activite/lot-:lot",
    name: "VerificationAssisteeWithActiviteLot",
    component: VerificationAssisteeLot,
    meta: {
      title: "Vérification assistée - PCP8",
      auth: true,
      roles: "USER",
    },
  },
  {
    path: "/activites/creer",
    name: "ActiviteCreer",
    component: OpeActiviteCreation,
    meta: {
      title: "Créer une activité - PCP8",
      auth: true,
      roles: "OPE",
    },
  },
  {
    path: "/administration/benevoles",
    name: "AdministrationBenevoles",
    component: Benevoles,
    meta: {
      title: "Bénévoles - PCP8",
      auth: true,
      roles: "NOUB",
    },
  },
  {
    path: "/administration/benevoles/creer",
    name: "AdministrationBenevolesCreer",
    component: BenevoleCreate,
    meta: {
      title: "Créer un bénévole - PCP8",
      auth: true,
      roles: "NOUB",
    },
  },
  {
    path: "/administration/benevole/:id",
    name: "BenevoleDetail",
    component: Benevole,
    meta: {
      title: "Bénévole - PCP8",
      auth: true,
      roles: "NOUB",
    },
  },
  {
    path: "/administration/parametrages",
    name: "AdministrationParametrages",
    component: Parametrages,
    meta: {
      title: "Paramétrages - PCP8",
      auth: true,
      roles: "ADMIN",
    },
  },
  {
    path: "/statistiques",
    name: "Statistiques",
    component: StatistiquesActivites,
    meta: {
      title: "Statistiques d'activités - PCP8",
      auth: true,
      roles: "STATS_GLB",
    },
  },
  {
    path: "/finances",
    name: "Finances",
    component: RestitutionFinanciere,
    meta: {
      title: "Restitution financière - PCP8",
      auth: true,
      roles: "FINC_COMPT",
    },
  },
  {
    path: "/tech/uniformes",
    name: "Tenues",
    component: Tenues,
    meta: {
      title: "Uniformes - PCP8",
      auth: true,
      roles: "LOC_TENUES",
    },
  },
  {
    path: "/go-photos",
    name: "GoPhotos",
    meta: {
      title: "Photos - PCP8",
      auth: true,
      roles: "USER",
    },
    beforeEnter() {
      const token = LocalStorageApp.getItem("pcp.token");
      window.location.href =
        "https://photos.protectioncivile-paris8.org/auth-vox.php?token=" +
        token;
    },
  },
  {
    path: "/connexion",
    name: "Login",
    component: Login,
    meta: {
      title: "Connexion - PCP8",
      auth: false,
    },
  },
  {
    path: "/deverrouiller",
    name: "Unlock",
    component: Unlock,
    meta: {
      title: "Dévérouiller - PCP8",
      auth: false,
    },
  },
  {
    path: "/*",
    name: "404",
    component: ErrorPage,
    meta: {
      title: "404 - Timeline PCP8",
      auth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  navigator.serviceWorker?.ready.then((registration) => {
    registration?.update();
  });

  if (to.meta && to.meta.auth) {
    const token = LocalStorageApp.getItem("pcp.token");
    if (token && JwtManage.isValid(`${token}`)) {
      const tokenDecoded = JwtManage.decode(token as string);
      await Http.sync();
      await login.context();
      const context = login.getContext;
      if (context?.roles.includes(to.meta.roles)) {
        if (
          context?.onboarding &&
          to.path !== "/onboarding" &&
          to.path !== "/recyclage"
        ) {
          next("/onboarding");
        } else {
          next();
        }
      } else {
        bus.$emit("habilitationRequired", [true, to.meta.roles]);
        return false;
      }
    } else {
      if (token) {
        next("/deverrouiller");
      } else {
        next("/connexion");
      }
    }
  }
  next();
});

router.afterEach((to) => {
  const title = to?.meta?.title ?? "PCP8";
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
